/**
 * @Author: panezhang
 * @Date: 2018-09-04 14:00:33.440
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:11
 */

import globalthis from 'globalthis';

import ua from 'ssr-common/util/webview/ua-parsed';

globalthis.shim();

export default {
    name: 'YqgPdfViewer',

    props: {
        url: {
            type: String,
            required: true
        },

        fullScreen: {
            type: Boolean,
            default: false
        },

        zoomable: {
            type: Boolean,
            default: true
        },

        defaultScale: {
            type: Number,
            default: 1.1
        },

        minScale: {
            type: Number,
            default: 0.25
        },

        maxScale: {
            type: Number,
            default: 10.0
        },

        signature: {
            type: Boolean,
            default: false
        },

        downloadable: {
            type: Boolean,
            default: false
        },

        iosSupport: {
            type: Boolean,
            default: false
        },

        legacy: {
            type: Boolean,
            default: false
        },

        workerSrc: {
            type: String,
            default: '/v3/public/pdfjs-dist/pdf.worker.js'
        },

        customHeader: {
            type: Object,
            default: () => ({})
        },
        // 可否打电话，是否显示"监督电话"图标
        enableCustomerServicePhone: {
            type: Boolean,
            default: false
        },
        // 可否跳转在线客服，是否显示"在线客服"图标
        enableCustomerOnline: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        pdfUrl() {
            if (this.url && this.url.startsWith('http://')) {
                return this.url.replace(/^http:\/\//, 'https://');
            }

            return this.url;
        }
    },

    methods: {
        async initViewer() {
            let PDFLib;
            let PDFViewerLib;
            if (this.legacy) {
                PDFLib = await import('pdfjs-dist/legacy/build/pdf.min');
                PDFViewerLib = await import('pdfjs-dist/legacy/web/pdf_viewer');
            } else {
                PDFLib = await import('pdfjs-dist');
                PDFViewerLib = await import('pdfjs-dist/web/pdf_viewer');
            }

            PDFLib.GlobalWorkerOptions.workerSrc = this.workerSrc;

            const container = this.$el.getElementsByClassName('raw-viewer-container')[0];
            if (!container) throw new Error('raw-viewer-container not found');

            const eventBus = new PDFViewerLib.EventBus();

            // init viewer & history
            const linkService = new PDFViewerLib.PDFLinkService({eventBus});
            const pdfViewer = new PDFViewerLib.PDFViewer({
                eventBus,
                container,
                linkService,
                removePageBorders: true,
                useOnlyCssZoom: true,
                textLayerMode: 0
            });

            const pdfHistory = new PDFViewerLib.PDFHistory({
                linkService,
                eventBus
            });

            linkService.setViewer(pdfViewer);
            linkService.setHistory(pdfHistory);

            eventBus.on('pagesinit', () => {
                // We can use pdfViewer now, e.g. let's change default scale.
                pdfViewer.currentScaleValue = 'auto';
            });

            Object.assign(this, {
                PDFLib,
                PDFViewerLib,

                linkService,
                pdfViewer,
                pdfHistory
            });
        },

        async open(url) {
            const {PDFLib, linkService, pdfViewer, pdfHistory, pdfLoadingTask, customHeader} = this;
            if (pdfLoadingTask) {
                // We need to destroy already opened document
                await this.close();
            }

            // load pdf from remote
            this.$emit('startLoadPdf');
            const loadingTask = PDFLib.getDocument({
                url,
                cMapUrl: '/v3/public/pdfjs-dist/cmaps/',
                cMapPacked: true,
                httpHeaders: {...customHeader}
            });
            const pdfDoc = await loadingTask.promise;
            pdfViewer.setDocument(pdfDoc);
            linkService.setDocument(pdfDoc);
            pdfHistory.initialize({fingerprint: pdfDoc.fingerprint, resetHistory: true});

            Object.assign(this, {
                pdfDoc,
                pdfLoadingTask: loadingTask
            });
        },

        async close() {
            if (!this.pdfLoadingTask) {
                return;
            }

            await this.pdfLoadingTask.destroy();

            this.pdfLoadingTask = null;
            if (this.pdfDoc) {
                this.pdfDoc = null;
                this.pdfViewer.setDocument(null);
                this.linkService.setDocument(null, null);
            }
        },

        zoomIn() {
            let newScale = this.pdfViewer.currentScale;

            if (newScale < this.maxScale) {
                newScale = (newScale * this.defaultScale).toFixed(2);
                newScale = Math.ceil(newScale * 10) / 10;
                newScale = Math.min(this.maxScale, newScale);
            }

            this.pdfViewer.currentScaleValue = newScale;
        },

        zoomOut() {
            let newScale = this.pdfViewer.currentScale;

            if (newScale > this.minScale) {
                newScale = (newScale / this.defaultScale).toFixed(2);
                newScale = Math.floor(newScale * 10) / 10;
                newScale = Math.max(this.minScale, newScale);
            }

            this.pdfViewer.currentScaleValue = newScale;
        }
    },

    async mounted() {
        if (!this.pdfUrl) {
            return;
        }

        if (ua.isWebview && ua.isIOS && !this.downloadable && !this.iosSupport) {
            location.replace(this.pdfUrl);

            return;
        }

        this.$emit('initViewerStart');
        await this.initViewer();
        this.$emit('initViewerEnd');
        await this.open(this.pdfUrl);
        this.$emit('loaded');
    },

    destroyed() {
        return this.close();
    },

    watch: {
        async url() {
            await this.open(this.pdfUrl);
        }
    }
};
