var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yqg-pdf-viewer",
      class: _vm.fullScreen ? "full-screen" : "",
    },
    [
      _c("div", { staticClass: "toolbar-container" }, [
        _vm.enableCustomerServicePhone
          ? _c(
              "a",
              {
                staticClass: "customer-service-phone",
                on: {
                  click: function ($event) {
                    return _vm.$emit("customer-service-phone")
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("./img/customer-service-phone.png") },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.enableCustomerOnline
          ? _c(
              "a",
              {
                staticClass: "customer-online",
                on: {
                  click: function ($event) {
                    return _vm.$emit("customer-online")
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("./img/customer-online.png") },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.zoomable
          ? _c("div", [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.zoomIn()
                    },
                  },
                },
                [_c("img", { attrs: { src: require("./img/zoom-in.png") } })]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.zoomOut()
                    },
                  },
                },
                [_c("img", { attrs: { src: require("./img/zoom-out.png") } })]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$root.isWebview && _vm.downloadable
          ? _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("download")
                  },
                },
              },
              [_c("img", { attrs: { src: require("./img/download.png") } })]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "rawViewerContainer", staticClass: "raw-viewer-container" },
        [
          _c("div", {
            staticClass: "pdfViewer raw-viewer",
            attrs: { id: "viewer" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }